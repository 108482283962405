import { Box, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { branchProductsAction, kitchenProductsAction } from "../asyncActions/branchActions";
import SubmitButton from "../UI/SubmitButton";
import { setError } from "../store/branchProductsSlice";
import { setCreateDocument } from "../store/gridDataSlice";
import SendIcon from '@mui/icons-material/Send';
import { setModal } from "../store/modalSlice";
import { useTranslation } from "react-i18next";




function DocuemntGrid() {

    const dispatch = useDispatch()

    const {t} = useTranslation()

    const {
        role
    } = useSelector(store => store.authorization)


    const {
        branchProducts,
        error,
        dataLoad
    } = useSelector(store => store.branchProducts)

    const {
        branchName,
        branchId
    } = useSelector(store => store.branch)

    const {
        createDocumentData
    } = useSelector(store => store.gridData)

    useEffect(() => {
        if (role === 4) {
            dispatch(kitchenProductsAction(branchId))
        } else {
            dispatch(branchProductsAction())
        }
    }, [])


    const apiRef = useGridApiRef()
    const gridRef = useRef()


    const rows = branchProducts.map((elem) => {
        let findData = createDocumentData.find(data => data.product_id === elem.product_id)

        return {
            id: elem.product_id,
            name: elem.product_name,
            category_name: elem.category,
            unit_type: elem.unit_type,
            current_stock: (findData) ? findData.current_stock : null

        }
    })

    const columns = [
        {
            field: 'category_name',
            headerName: t('grid.category'),
            width: 100,
            cellClassName: 'sticky-column',
            editable: false,
        },
        { field: 'id', headerName: 'ID', width: 40 },
        {
            field: 'name',
            headerName: t('grid.product_name'),
            width: 250,
            rowSpanValueGetter: () => { },
            editable: false,
        },
        {
            field: 'current_stock',
            headerName: t('grid.current_stock'),
            type: 'number',
            width: 130,
            rowSpanValueGetter: () => { },
            editable: true,
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            rowSpanValueGetter: () => { },
            width: 130,
            editable: false,
        }
    ]



    const handleSubmit = () => {
        setTimeout(() => {
            let gridProducts = new Array(...apiRef.current.getRowModels()).map(el => el[1])
            if (gridProducts.some(elem => typeof (elem.current_stock) !== 'number')) {
                dispatch(setError('Validate Error. You miss current stock at row'))
            } else {
                dispatch(setError(null))
                dispatch(setModal({ active: true, content: 'createDocument' }))
            }
        }, 100)
    }

    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch { }
    },
        [apiRef],
    );

    const onCeilEdit = () => {
        setTimeout(() => {
            let gridProducts = new Array(...apiRef.current.getRowModels()).map(el => el[1])
            const payload = gridProducts.map(elem => ({
                product_id: elem.id,
                current_stock: elem.current_stock
            }))
            dispatch(setCreateDocument(payload))
        })
    }



    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
                <Typography variant="h4">{branchName}</Typography>
            </Box>
            <Box>
                {error && <Typography variant="h6" color="red">{error}</Typography>}
            </Box>
            <Box
                sx={{ minWidth: '300px', height: '60vh' }}
                ref={gridRef}
            >
                <DataGrid
                    loading={dataLoad}
                    columns={columns}
                    rows={rows}
                    apiRef={apiRef}
                    ref={gridRef}
                    onCellEditStop={onCeilEdit}
                    unstable_rowSpanning
                    showCellVerticalBorder
                    pageSize={200}
                    pinnedColumns={{ left: ['category_name'] }} // Закрепляем столбец "Category"
                    onCellClick={handleCellClick}
                />
            </Box>
            <Box sx={{
                alignSelf: 'flex-end'
            }}>
                <SubmitButton endIcon={<SendIcon />} onClick={handleSubmit}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}




export default DocuemntGrid