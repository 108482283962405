import { instance } from "./interceptors"



export const allWarhousesUnit = async() => {
    const response = await instance.get('/api/branch/all')
    return response.data
}

export const warhouseProductsUnit = async(params) => {
    const query = (params) ? `/?branchType=${params.type}&branchId=${params.branch}` : ''
    const response = await instance.get('/api/warhouse'+query)
    return response.data
}


