import { createSlice } from "@reduxjs/toolkit";


const modalSlice = createSlice({
    name: 'modalSlice',
    initialState: {
        active: false,
        content: null,
        data: null
    },
    reducers: {
        setModal(state, action){
            const {
                content,
                active,
                data
            } = action.payload
            state.active = active
            state.content = content ?? null
            state.data = data ?? null
        }
    }
})

export default modalSlice.reducer

export const {
    setModal
} = modalSlice.actions