import { createBrowserRouter, useLocation } from 'react-router-dom'
import Layout from './Layout'
import AuthPage from './pages/AuthPage'
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { checkTokenAction } from './asyncActions/authActions';
import { logout } from './store/authSlice';
import HomePage from './pages/HomePage';
import DocumentPage from './pages/DocumentPage';
import ProductsBufferPage from './pages/ProductsBufferPage';
import DocumentListPage from './pages/DocumentListPage';
import ProductVendorPage from './pages/ProductVendorPage';
import SupplyListPage from './pages/SupplyListPage';
import ManagePage from './pages/ManagePage';
import UserListPage from './pages/UserListPage';
import UserEditPage from './pages/UserEditPage';
import DashboardPage from './pages/DashboardPage';
import UnscheduledDocumentPage from './pages/UnscheduledDocumentPage';
import WarehouseManagePage from './pages/WarehouseManagePage';
import WarehousePage from './pages/WarehousePage';
import AdmissionManagePage from './pages/SupplyManagePage';
import DocumentWasteGrid from './components/DocumentWasteGrid';
import DocuemntGrid from './components/DocumentGrid';

const AuthRoute = ({ Component }) => {
    const dispatch = useDispatch()
    const {
        isAuthenticated
    } = useSelector(store => store.authorization)
    const {pathname} = useLocation()
    useEffect(() => {
        if (!isAuthenticated){
            dispatch(logout())            
        }
        dispatch(checkTokenAction())
    },[isAuthenticated, pathname])
    return isAuthenticated ? <Component/> : <Navigate to="/login" />;
};

const PrivateRoleRoute = ({Component, role_id}) => {
    const {
        role
    } = useSelector(store => store.authorization)
    return role === role_id ? <AuthRoute Component={Component}/> : <Navigate to='/'/>;
}

export const router = createBrowserRouter([
    {
      path: '/', 
      element: <Layout/>,
      children: [
          {path: '/', element: <AuthRoute Component={HomePage}/> },
          {path: '/create/document', element: <AuthRoute Component={DocuemntGrid}/> },
          {path: '/create/document/waste', element: <AuthRoute Component={DocumentWasteGrid}/> },
          {path: 'create/document/unschedule', element: <AuthRoute Component={UnscheduledDocumentPage}/> },
          {path: '/admission', element: <AuthRoute Component={ProductVendorPage}/> },
          {path: '/admission/list', element: <AuthRoute Component={SupplyListPage}/> },
          {path: '/document/:id', element: <AuthRoute Component={DocumentPage}/> },
          {path: '/manage/warehouse', element: <AuthRoute Component={WarehouseManagePage}/> },
          {path: '/manage/admission', element: <AuthRoute Component={AdmissionManagePage}/> },
          {path: '/warehouse', element: <AuthRoute Component={WarehousePage}/> },
          {path: '/buffer/products', element: <PrivateRoleRoute role_id={5} Component={ProductsBufferPage}/> },
          {path: '/users', element: <PrivateRoleRoute role_id={5} Component={UserListPage}/> },
          {path: '/user/create', element: <PrivateRoleRoute role_id={5} Component={UserEditPage}/> },

          {path: '/user/edit/:id', element: <PrivateRoleRoute role_id={5} Component={UserEditPage}/> },
          {path: '/document/archive', element: <PrivateRoleRoute role_id={5} Component={DocumentListPage}/> },
          {path: '/manage', element: <PrivateRoleRoute role_id={5} Component={ManagePage}/> },
          {path: '/login', element: <AuthPage/> },


          {path: '/dashboard', element: <AuthRoute Component={DashboardPage}/> },

      ]
      },  
  ])