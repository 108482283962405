import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allWarehouseListAction, warhouseProductsAction } from "../asyncActions/warhouseActions";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DataGridFullScreen from "../components/DataGridFullScreen";


function WarehouseManagePage() {

    const apiRef = useGridApiRef()

    const dispatch = useDispatch()

    const [type, setType] = useState(0)

    const [branch, setBranch] = useState(0)

    const {
        products,
        dataLoad,
        warehouseList,
        branchList,
    } = useSelector((store) => store.warhouse)

    const branchOptions = type === 1 ? warehouseList : branchList

    const rows = products

    const columns = [

        { field: 'id', headerName: 'ID', width: 40 },
        {
            field: 'name',
            headerName: 'Product name',
            width: 250,
            editable: false,
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            width: 130,
            editable: false,
        },
        {
            field: 'avg_price',
            headerName: 'Avg price',
            type: 'number',
            width: 130,
            editable: true,
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            type: 'number',
            width: 130,
            editable: true,
        },
    ]

    useEffect(() => {
        dispatch(allWarehouseListAction())
    }, [dispatch])

    useEffect(() => {
        if(type !== 0 && branch !== 0){
            const params = {
                type, 
                branch
            }
            dispatch(warhouseProductsAction(params))
        }
    }, [branch, type])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px'
        }}>
            <Typography variant="h4" align="center">Warehouse inventory</Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                justifyContent: 'center'
            }}>
                <FormControl sx={{ width: '250px' }}>
                    <InputLabel id="warhouse_type">Warehouse Type</InputLabel>
                    <Select
                        defaultValue={type}
                        disabled={dataLoad}
                        labelId="warhouse_type"
                        label="Warehouse Type"
                        onChange={(e) => setType(e.target.value)}
                        required
                    >
                        <MenuItem value={1}>Warehouse</MenuItem>
                        <MenuItem value={2}>Branch</MenuItem>
                    </Select>
                </FormControl>
                
                <FormControl sx={{ width: '250px' }}>
                    <InputLabel id="warhouse_id">{type === 1 ? 'Warehouse' : 'Branch'} id</InputLabel>
                    <Select
                        defaultValue={type}
                        disabled={dataLoad}
                        labelId="warhouse_id"
                        label={type === 1 ? 'Warehouse' : 'Branch' + ' id'}
                        onChange={(e) => setBranch(e.target.value)}
                        required
                    >
                        {branchOptions.map(branch => <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            <Box
                sx={{ minWidth: '300px', height: '60vh' }}
            >
                <DataGrid
                    loading={dataLoad}
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    showCellVerticalBorder
                />
            </Box>
        </Box>
    )
}

export default WarehouseManagePage
