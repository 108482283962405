import { createSlice } from "@reduxjs/toolkit";
import { act } from "react";

const gridDataSlice = createSlice({
    name: 'gridDataSlice',
    initialState: {
        createDocumentData: [],
        updateDocumentData: [],
        createDocumentWasteData: [],
        declineDocumentData: [],
        vendorDocumentData: [],
        unscheduleDocumentData: [],
        declineMode: false
    },
    reducers: {
        setCreateDocument(state, action){
            state.createDocumentData = action.payload
        },
        clearData(state, action){
            if (action.payload){
                state[action.payload] = []
            } else {
                state.createDocumentData = []
                state.updateDocumentData = []
                state.createDocumentWasteData = []
                state.vendorDocumentData = []
                state.unscheduleDocumentData = []
            }
        },
        setUpdateDocument(state, action){
            state.updateDocumentData = action.payload
        },
        updateCreateWasteData(state, action){
            const findIndex = state.createDocumentWasteData.findIndex(el => el.id === action.payload.id)
            
            if (findIndex === -1){
                state.createDocumentWasteData = [...state.createDocumentWasteData, action.payload]
            } else {
                if (action.payload.quantity === 0){
                    state.createDocumentWasteData=state.createDocumentWasteData.filter(el => el.id !== action.payload.id)
                } else {
                    let copyData = [...state.createDocumentWasteData]
                    copyData[findIndex] = {...action.payload}
                    state.createDocumentWasteData = copyData
                }

            }
        },
        setDeclineMode(state){
            state.declineMode = !state.declineMode
        },
        setDeclineDocumentData(state, action){
            const findIndex = state.declineDocumentData.findIndex(el => el.id === action.payload.id)
            
            if (findIndex === -1){
                state.declineDocumentData = [...state.declineDocumentData, action.payload]
            } else {
                if (!action.payload.current_quantity){
                    state.declineDocumentData=state.declineDocumentData.filter(el => el.id !== action.payload.id)
                } else {
                    let copyData = [...state.declineDocumentData]
                    copyData[findIndex] = {...action.payload}
                    state.declineDocumentData = copyData
                }

            }
        },
        setVendorDocumentData(state, action){
            const row = action.payload
            let findRow = state.vendorDocumentData.find(el => el.id === row.id)
            findRow.quantity = row.quantity 
            findRow.price = row.price 
            findRow.avg_price = row.avg_price 
        },
        addVendorDocumentData(state, action){
            state.vendorDocumentData = [...state.vendorDocumentData, action.payload]
        },
        removeVendorDocumentData(state, action){
            state.vendorDocumentData = state.vendorDocumentData.filter(elem => elem.id !== action.payload)
        },
        tooglePriceVendorDocument(state, action){
            const productId = action.payload
            const findProduct = state.vendorDocumentData.find(el => el.id === productId)
            findProduct.typePrice = !findProduct.typePrice
            findProduct.avg_price = 0
            findProduct.price = 0
        },
        setUnscheduleDocumentData(state, action){
            const row = action.payload
            let findRow = state.unscheduleDocumentData.find(el => el.id === row.id)
            findRow.quantity = row.quantity 
 
        },
        addUnscheduleDocumentData(state, action){
            state.unscheduleDocumentData = [...state.unscheduleDocumentData, action.payload]
        },
        removeUnscheduleDocumentData(state, action){
            state.unscheduleDocumentData = state.unscheduleDocumentData.filter(elem => elem.id !== action.payload)
        },
    },
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(updateDocumentProductsAction.fulfilled, (state, action, {getState}) => {                
    //             const {documentProducts, documents} = getDocumentProductData(getState())
    //             let productsDocumentId = documentProducts.map(el => el.id)
    //             state.updateDocumentData = state.updateDocumentData.filter( el => productsDocumentId.includes(el.product_document_id))
    //         })
    // }
})

export default gridDataSlice.reducer


export const getGridData = (state) => state.gridData

export const {
    setCreateDocument,
    setUpdateDocument,
    updateCreateWasteData,
    clearData,
    setDeclineDocumentData,
    setDeclineMode,
    setVendorDocumentData,
    addVendorDocumentData,
    removeVendorDocumentData,
    tooglePriceVendorDocument,
    setUnscheduleDocumentData,
    addUnscheduleDocumentData,
    removeUnscheduleDocumentData
} = gridDataSlice.actions




