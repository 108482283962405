import { Box, TextField, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { createVendorDocumentAction } from "../../../asyncActions/vendorActions";
import { clearFilterVendor } from "../../../store/vendorSlice";
import { useMemo } from "react";
import { createUnscheduledDocumentAction } from "../../../asyncActions/documentActions";
import { useTranslation } from "react-i18next";

function VendorDocumentContent({type}) {

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const {
        vendorDocumentData,
        unscheduleDocumentData
    } = useSelector(store => store.gridData)

    const {
        branchId
    } = useSelector(store => store.branch)


    const submitHandle = () => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {}
        }

        if (type === 'unschedule'){
            modalPayload.data.text = t('modal.doc.uns_create')
            modalPayload.data.path = '/'

            const body = {
                branch_id: branchId,
                products: unscheduleDocumentData.map(elem => ({id: elem.id, quantity: elem.quantity})),
            }
            dispatch(createUnscheduledDocumentAction(body))
        } else if (type === 'vendor'){
            modalPayload.data.text =  t('modal.doc.create')
            modalPayload.data.path = '/admission/list'
            dispatch(clearFilterVendor())
            dispatch(createVendorDocumentAction(vendorDocumentData))
        }
        dispatch(setModal(modalPayload))

    }

    const headerGetter = useMemo(() => {
        let value
        if (type === 'vendor'){
            value =  t('modal.approve.product')
        } else if (type === 'unschedule'){
            value =  t('modal.approve.uns_product')
        }
        return value
    }, [])

    const listData = (type === 'vednor') ? vendorDocumentData : unscheduleDocumentData 

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
             <Typography>{headerGetter}</Typography>
            <ul>
                {listData.map(elem => (
                    <li>{elem.name || elem.product_name}: <span style={{ color: 'orange' }}>{elem.quantity}</span></li>
                ))}
            </ul>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default VendorDocumentContent
