import { Box, Typography } from "@mui/material"
import SubmitButton from "../UI/SubmitButton"
import { useDispatch, useSelector } from "react-redux"
import EntryButton from "../UI/EntryButton"
import { setModal } from "../store/modalSlice"
import { useLocation } from "react-router-dom"
import { setDeclineMode } from "../store/gridDataSlice"

import SendIcon from '@mui/icons-material/Send';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useTranslation } from "react-i18next"

function ButtonSection() {
    const { t } = useTranslation();

    const {
        document
    } = useSelector(store => store.documentProducts)

    const {
        role
    } = useSelector(store => store.authorization)

    const {
        declineMode
    } = useSelector(store => store.gridData)

    const dispatch = useDispatch()
    const location = useLocation()

    let status = document.status
    let isManager = (role === 5)


    if ([1, 4].includes(role)) {
        if (location.pathname.split('/')[3] === 'waste') {

            function handleSubmit() {
                setTimeout(() => {
                    dispatch(setModal({ active: true, content: 'wasteDocument' }))
                }, 10)
            }

            return (
                <Box sx={{
                    display: 'flex',
                    padding: '15px 0px',
                    gap: '15px',
                    justifyContent: 'end'
                }}>
                    <SubmitButton endIcon={<SendIcon />} onClick={handleSubmit}>{t('button.submit')}</SubmitButton>
                </Box>
            )
        }


        function handleSubmit() {
            if (declineMode) {
                dispatch(setModal({ active: true, content: 'declineDocument' }))
            } else {
                dispatch(setModal({ active: true, content: 'aproveDocument' }))
            }
        }

        function handleDecline() {
            if (!declineMode) {
                dispatch(setModal({ active: true, content: 'action', data: { text: t('modal.doc.decline_mode') } }))
            }
            dispatch(setDeclineMode())
        }

        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                justifyContent: 'space-between'
            }}>
                <EntryButton endIcon={declineMode ? <HighlightOffIcon /> : <ThumbDownOffAltIcon />} disabled={document.isDeclined || status !== 3} onClick={handleDecline}>
                    {declineMode ? t('button.cancel') : t('button.decline')}
                </EntryButton>
                <SubmitButton endIcon={declineMode ? <SendIcon /> : <ThumbUpOffAltIcon />} disabled={document.isDeclined || status !== 3} onClick={handleSubmit}>
                    {declineMode ? t('button.approve_decline') : t('button.approve')}
                </SubmitButton>
            </Box>
        )
    }

    if ([2, 5].includes(role)) {

        function handleSave() {
            setTimeout(() => {
                dispatch(setModal({ active: true, content: 'saveDocument' }))
            }, 100)
        }

        function handleSubmit() {
            setTimeout(() => {
                dispatch(setModal({ active: true, content: 'submitDocument' }))
            }, 100)
        }
        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                // gap: '15px',
                justifyContent: 'space-between'
            }}>
                <SubmitButton endIcon={<BookmarkBorderIcon />} disabled={status !== 1 || document.isDeclined} onClick={handleSave}>{t('button.save')}</SubmitButton>
                {role === 5 ?
                    <EntryButton endIcon={<SendIcon />} onClick={handleSubmit}>{t('button.submit')}</EntryButton>
                    :
                    <EntryButton endIcon={<SendIcon />} disabled={status !== 1 || document.isDecline} onClick={handleSubmit}>{t('button.submit')}</EntryButton>


                }
            </Box>
        )
    }


    if (role === 3) {

        function handleSubmit() {
            if (declineMode) {
                dispatch(setModal({ active: true, content: 'declineDocument' }))
            } else {
                dispatch(setModal({ active: true, content: 'aproveDocument' }))
            }
        }

        function handleDecline() {
            if (!declineMode) {
                dispatch(setModal({ active: true, content: 'action', data: { text: 'Decline mode activated' } }))
            }
            dispatch(setDeclineMode())
        }
        return (
            <Box sx={{
                display: 'flex',
                padding: '15px 0px',
                justifyContent: 'space-between'
            }}>
                <EntryButton endIcon={declineMode ? <HighlightOffIcon /> : <ThumbDownOffAltIcon />} disabled={document.isDeclined || status !== 2} onClick={handleDecline}>
                    {declineMode ? t('button.cancel') : t('button.decline')}
                </EntryButton>
                <SubmitButton endIcon={declineMode ? <SendIcon /> : <ThumbUpOffAltIcon />} disabled={document.isDeclined || status !== 2} onClick={handleSubmit}>
                    {declineMode ? t('button.approve_decline') : t('button.approve')}
                </SubmitButton>
            </Box>
        )
    }
}


export default ButtonSection