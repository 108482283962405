
import { createSlice } from "@reduxjs/toolkit";
import { DocumentVendorProductsAction, documentVendorProductsEditAction, vendorProductsAction } from "../asyncActions/vendorActions";
import dayjs from "dayjs";


function transformData(data){
    let vendors = []
    let vendorProducts = []
    for(let elem of data){
        if (!vendors.some(el => el.id === elem.vendor.id)){
            vendors.push(elem.vendor)
        }
        elem.vendor = elem.vendor.id
        vendorProducts.push(elem)
    }
    return {
        vendors,
        vendorProducts
    }
}


const vendorSlice = createSlice({
    name: 'vendorSlice',
    initialState: {
        selectFilter: {
            vendor: "",
            product: ''
        },
        dateFilter: {
            date: dayjs()
        },
        branchFilter: {
            branchId: 0,
            branchType: 0,
        },
        vendorList: [],
        productList: [],
        productVendorList: [],
        productDocumentVendotList: [],
        dataLoad: false,
    },
    reducers: {
        clearFilterVendor(state){
            state.selectFilter = {
                vendor: '',
                product: ''
            }
        },
        setSelectFilter(state, action){
            const {filter, value} = action.payload
            state.selectFilter[filter] = value
        },        
        setBranchFilter(state, action){
            const {field, value} = action.payload
            if (field === 'branchId' || field === 'branchType'){
                state.branchFilter[field] = value
            }
        },
        setDate(state, action){
            state.dateFilter.date = action.payload
        },
        setProductVendor(state,action){
            const vendorId = action.payload
            state.productVendorList = state.productList.filter(product => product.vendor === vendorId)
        }
    }, 
    extraReducers: (builder) => {
        builder 
            .addCase(vendorProductsAction.fulfilled, (state, action) => {
                const {vendors, vendorProducts} = transformData(action.payload)
                state.vendorList = vendors
                state.productList = vendorProducts
                state.dataLoad = false
            })
            .addCase(vendorProductsAction.rejected, (state, action) => {
                state.dataLoad = false
            })
            .addCase(vendorProductsAction.pending, (state, action) => {
                state.dataLoad = true
            })

            .addCase(DocumentVendorProductsAction.rejected, (state, action) => {
                state.dataLoad = false
            })
            .addCase(DocumentVendorProductsAction.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(DocumentVendorProductsAction.fulfilled, (state, action) => {
                state.dataLoad = false
                state.productDocumentVendotList = action.payload
            })
            .addCase(documentVendorProductsEditAction.rejected, (state, action) => {
                state.dataLoad = false
            })
            .addCase(documentVendorProductsEditAction.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(documentVendorProductsEditAction.fulfilled, (state, action) => {
                state.dataLoad = false
            })
    }
})


export default vendorSlice.reducer

export const getBufferData = (state) => state.vendor

export const {
    clearFilterVendor,
    setSelectFilter,
    setBranchFilter,
    setProductVendor,
    setDate
} = vendorSlice.actions
