import { Box, CircularProgress, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


function ActionContent() {

    const navigate = useNavigate()

    const {t} = useTranslation()

    const {
        postLoad,
        error
    } = useSelector(store => store.postLoad)

    const {
        data,
    } = useSelector(store => store.modal)

    const dispatch = useDispatch()

    const submitHandle = () => {
        dispatch(setModal({active: false}))
        if (!error && data.path) {
            navigate(data.path)
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            {postLoad ?
                <CircularProgress /> :
                <>
                    <Typography variant="h6">{error || data.text}</Typography>

                </>
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%'
            }}>
                <SubmitButton disabled={postLoad} onClick={submitHandle}>{t('button.approve')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default ActionContent