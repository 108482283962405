import { Box, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useMemo } from "react";
import { archiveProductsDocumentAction, createDocuemntAction, deliveryProductsDocumentAction, submitProductsDocumentAction, updateDeclineDocumentProductsAction, updateDocumentProductsAction } from "../../../asyncActions/documentActions";
import { useTranslation } from "react-i18next";

function DocumentEditContent({ type }) {
    const {t} = useTranslation()

    const dispatch = useDispatch()


    const {
        branchId
    } = useSelector(store => store.branch)

    const {
        role
    } = useSelector(store => store.authorization)


    const {
        createDocumentData,
        createDocumentWasteData
    } = useSelector(store => store.gridData)

    const headerGetter = useMemo(() => {
        let value
        if (type === 'create') {
            value = t('modal.questions.create')
        } else if (type === 'submit') {
            value = t('modal.questions.submit')
        } else if (type === 'save') {
            value = t('modal.questions.save')
        } else if (type === 'aprove') {
            value = t('modal.questions.approve')
        } else if (type === 'decline') {
            value = t('modal.questions.decline')
        } else if (type === 'waste') {
            value = t('modal.questions.waste')
        }
        return value
    }, [dispatch])

    const submitHandle = () => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {}
        }

        if (type === 'create') {
            modalPayload.data.text = t('modal.doc.create')
            modalPayload.data.path = '/'
            dispatch(setModal(modalPayload))
            let documentPayalod = {
                products: createDocumentData,
                branch_id: branchId
            }

            dispatch(createDocuemntAction(documentPayalod))
        } else if (type === 'submit') {
            modalPayload.data.text = t('modal.doc.submit')
            dispatch(setModal(modalPayload))
            dispatch(submitProductsDocumentAction())
        } else if (type === 'save') {
            modalPayload.data.text = t('modal.doc.save')
            dispatch(setModal(modalPayload))
            dispatch(updateDocumentProductsAction())
        } else if (type === 'aprove') {
            modalPayload.data.text = t('modal.doc.approve')
            modalPayload.data.path = '/'
            if (role === 1 || role === 4) {
                dispatch(archiveProductsDocumentAction())
            } else if (role == 3) {
                dispatch(deliveryProductsDocumentAction())
            }
            dispatch(setModal(modalPayload))
        } else if (type === 'decline') {
            modalPayload.data.text = t('modal.doc.decline')
            modalPayload.data.path = '/'
            dispatch(setModal(modalPayload))
            dispatch(updateDeclineDocumentProductsAction())
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography  variant="h6">{headerGetter}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default DocumentEditContent
