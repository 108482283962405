import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {warhouseProductsAction } from "../asyncActions/warhouseActions";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DataGridFullScreen from "../components/DataGridFullScreen";
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SubmitButton from "../UI/SubmitButton";
import { useTranslation } from "react-i18next";


function WarehousePage() {

    const {t} = useTranslation()

    const apiRef = useGridApiRef()

    const dispatch = useDispatch()

    const {
        products,
        dataLoad
    } = useSelector((store) => store.warhouse)

    const rows = products

    // 120 216 600 
    // 240 433 200
    const defaultColumns = [

        { field: 'id', headerName: 'ID', width: 40},
        {
            field: 'name',
            flex: 1,
            headerName: t('grid.product_name'),
            minWidth: 250,
            editable: false,
        },
        {
            field: 'unit_type',
            flex: 1,
            headerName: t('grid.product_name'),
            minWidth: 130,
            editable: false,
        },
        {
            field: 'avg_price',
            flex: 1,
            headerName: t('grid.avg_price'),
            type: 'number',
            minWidth: 130,
            editable: false,
        },
        {
            field: 'quantity',
            flex: 1,
            headerName: t('grid.qnt'),
            type: 'number',
            minWidth: 130,
            editable: false,
        }
    ]

    const [columns, setColumns] = useState(defaultColumns)
    const [correctMode, setCorrectMode] = useState(false)

    const handleCorrectMode = () => {
        setCorrectMode(!correctMode)
        if (correctMode){
            setColumns([
                ...defaultColumns,
                {
                    field: 'current_quantity',
                    headerName: t('grid.current_qnt'),
                    type: 'number',
                    minWidth: 130,
                    editable: false,
                },
                {
                    field: 'actions',
                    headerName: t('grid.action'),
                    minWidth: 130,
                    editable: false,
                    renderCell: () => {
                        return (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <SubmitButton>
                                    <ChangeCircleIcon/>
                                </SubmitButton>
                            </Box>
                        )
                    }
                },
            ])
        } else {
            setColumns(defaultColumns)
        }
    }


    useEffect(() => {
        dispatch(warhouseProductsAction())
    }, [dispatch])

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '25px'
        }}>
            <Typography variant="h4" align="center">{t('page_text.warhouse_inventory')}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <SubmitButton
                    onClick={handleCorrectMode}
                >
                    {t('button.correct')} 
                </SubmitButton>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                justifyContent: 'center'
            }}>
            </Box>
            <Box
                sx={{ minWidth: '300px', height: '60vh' }}
            >
                <DataGrid
                    loading={dataLoad}
                    apiRef={apiRef}
                    columns={columns}
                    rows={rows}
                    showCellVerticalBorder
                />
            </Box>
        </Box>
    )
}

export default WarehousePage
