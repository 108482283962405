import { Box, Button, Modal } from "@mui/material";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import SubmitButton from "../UI/SubmitButton";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useState } from "react";


const modalBoxStyle = {
    position: "absolute",
    top: 0,
    left: '50%',
    transform: "translate(-50%, 0)",
    width: '100wh',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    mt: 4,
    p: 4,
};


function DataGridFullScreen(props) {
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <>
            <DataGrid
                {...props}
                slots={{
                    toolbar: () =>
                        show ? null : (
                            <GridToolbarContainer>
                                <Button onClick={handleClick} sx={{ p: 1 }} variant="outlined">
                                    <FullscreenIcon /> 
                                </Button>
                            </GridToolbarContainer>
                        ),
                }}
            />
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={show}
                onClose={handleClick}
            >
                <Box sx={modalBoxStyle}>
                    <Box
                        sx={{ height: "80vh", width: "80vw" }}

                    >
                        {show ? (
                            <DataGrid
                                {...props}
                                initialState={props.apiRef.current.exportState()}
                            />
                        ) : null}
                    </Box>
                </Box>
            </Modal>
        </>
    )
}

export default DataGridFullScreen