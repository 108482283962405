import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, TextField, MenuItem, Select, InputLabel, FormControl, FormHelperText, Paper, Typography, CircularProgress } from "@mui/material";
import { userDetailAction } from "../asyncActions/userActions";
import SubmitButton from "../UI/SubmitButton";
import { useLocation, useParams } from "react-router-dom";
import { setModal } from "../store/modalSlice";
import EntryButton from "../UI/EntryButton";
import { clearUserDetail } from "../store/userSlice";
import { allWarehouseListAction } from "../asyncActions/warhouseActions";

function UserEditPage() {
    const dispatch = useDispatch();
    const { branchList, warehouseList,dataLoad, userDetail } = useSelector((state) => state.user);
    const location = useLocation()

    const { id } = useParams()

    const type = useMemo(() => {
        let value
        if (location.pathname.includes('create')) {
            value = 'create'
        } else if (location.pathname.includes('edit')) {
            value = 'edit'
        } else {
            value = 'none'
        }
        return value
    }, [dispatch])

    useEffect(() => {
        dispatch(allWarehouseListAction())
        if (type === 'edit') {
            dispatch(userDetailAction(id));
            return () => {
                dispatch(clearUserDetail())
            }
        }
        return () => {
            reset()
        }
    }, [dispatch]);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset
    } = useForm();

    const branchGetter = useMemo(() => {
        return userDetail.branches.length === 1 ? userDetail.branches[0].id : 0
    }, [userDetail.user])

    const warhouseGetter = useMemo(() => {
        return userDetail.warhouses.length === 1 ? userDetail.warhouses[0].id : 0
    }, [userDetail.user])

    useEffect(() => {
        if (type === 'edit' && userDetail?.user) {
            setValue('username', userDetail.user.username || '');
            setValue('role', userDetail.user.role || '');
            setValue('branch', branchGetter || '');
            setValue('warhouse', warhouseGetter || '');
        }
    }, [userDetail, type, setValue]);

    const onSubmit = (data) => {
        if (type === 'create') {
            const params = {
                username: data.username,
                password: data.password,
                role: data.role,
            }
            if (data.branch) {
                params.branch = data.branch
            }
            if (data.warhouse){
                params.warhouse = data.warhouse
            }
            console.log(params)
            dispatch(setModal({ active: true, content: 'userCreate', data: params }))
        } else if ((type === 'edit')) {
            const params = {
                id: id,
                body: {
                    username: data.username,
                    role: data.role
                },
            }
            if (data.password) {
                params.body.password = data.password
            }
            if (data.branch) {
                params.body.branch = data.branch
            }
            if (data.warhouse){
                params.body.warhouse = data.warhouse
            }
            dispatch(setModal({ active: true, content: 'userEdit', data: params }))
        }
    };


    const password = watch("password");
    const role_value = watch("role")
    const branch_value = watch("branch")
    const warhouse_value = watch("warhouse")

    const handleDelete = () => {
        const modalPyaload = {
            active: true,
            content: 'userDelete',
            data: {
                username: userDetail.user.username,
                id: id
            }
        }
        dispatch(setModal(modalPyaload))
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "25px" }}>
            {dataLoad ? <CircularProgress /> :
                <>
                    {type === 'create' ?
                        <Typography variant="h5" align="center">Add User</Typography> :
                        <Typography variant="h5" align="center">Editting <span style={{ color: 'orange' }}>{userDetail.user.username}</span> </Typography>
                    }
                    <Paper sx={{ padding: 2, width: "100%", maxWidth: "600px" }}>
                        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '40px',
                            }}>
                                {/* Имя пользователя */}
                                <TextField
                                    label="Username"
                                    {...register("username", { required: 'Username is required' })}
                                    error={Boolean(errors.username)}
                                    helperText={errors.username?.message}
                                    fullWidth
                                // defaultValue={(type === 'edit' ? userDetail.user.username : '')}

                                />

                                {/* Пароль */}
                                <TextField
                                    label={type === 'edit' ? 'Reset password' : "Password"}
                                    {...register("password", { required: { value: type !== 'edit', message: "Passowrd is required" } })}
                                    error={Boolean(errors.password)}
                                    helperText={errors.password?.message}
                                    fullWidth
                                />

                                <FormControl fullWidth error={Boolean(errors.role)}>
                                    <InputLabel>Role</InputLabel>
                                    <Select
                                        {...register("role", { required: "Role is required" })}
                                        label="Role"
                                        value={role_value}
                                    >
                                        <MenuItem value={1}>Sales Man</MenuItem>
                                        <MenuItem value={2}>Warhouse Man</MenuItem>
                                        <MenuItem value={3}>Supplier</MenuItem>
                                        <MenuItem value={4}>Kitchen</MenuItem>
                                        <MenuItem value={5}>Manager</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.role?.message}</FormHelperText>
                                </FormControl>

                                <TextField
                                    label={"Confirm password"}
                                    {...register("confirmPassword", {
                                        validate: (value) =>
                                            value === password || "Passwords do not match",
                                    })}
                                    error={Boolean(errors.confirmPassword)}
                                    helperText={errors.confirmPassword?.message}
                                    fullWidth
                                />

                                {role_value === 1 &&
                                    <FormControl fullWidth error={Boolean(errors.branch)}>
                                        <InputLabel>Branch</InputLabel>
                                        <Select
                                            {...register("branch", { required: "Branch is required" })}
                                            label="Branch"
                                            value={branch_value}

                                        >
                                            {branchList && branchList.filter(branch => !branch.isKitchen).map((branch) => (
                                                <MenuItem key={branch.id} value={branch.id}>
                                                    {branch.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.branch?.message}</FormHelperText>
                                    </FormControl>

                                }
                                {role_value === 2 &&
                                    <FormControl fullWidth error={Boolean(errors.branch)}>
                                        <InputLabel>Warhouse</InputLabel>
                                        <Select
                                            {...register("warhouse", { required: "Warhouse is required" })}
                                            label="warhouse"
                                            value={warhouse_value}

                                        >
                                            {warehouseList && warehouseList.map((warhouse) => (
                                                <MenuItem key={warhouse.id} value={warhouse.id}>
                                                    {warhouse.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{errors.branch?.message}</FormHelperText>
                                    </FormControl>

                                }
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}>
                                {type === 'edit' && <EntryButton onClick={handleDelete}> Delete</EntryButton>}
                                <SubmitButton type="submit">
                                    Submit
                                </SubmitButton>
                            </Box>
                        </form>
                    </Paper>
                </>
            }
        </Box>
    );
}

export default UserEditPage;
