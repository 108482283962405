import { Autocomplete, Box, FormControl, Icon, InputLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { DataGrid, GridEditInputCell, useGridApiRef } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SubmitButton from "../UI/SubmitButton";
import { useEffect, useState, useCallback } from "react";
import EntryButton from "../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { vendorProductsAction } from "../asyncActions/vendorActions";
import { addVendorDocumentData, clearData, removeVendorDocumentData, setVendorDocumentData, tooglePriceVendorDocument } from "../store/gridDataSlice";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { clearFilterVendor, setProductVendor, setSelectFilter } from "../store/vendorSlice";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { setModal } from "../store/modalSlice";
import { useTranslation } from "react-i18next";



const style = {
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    }
}



function ProductVendorPage() {

    const { t } = useTranslation()

    const apiRef = useGridApiRef()
    const dispatch = useDispatch()

    const {
        vendorList,
        productList,
        selectFilter,
        productVendorList
    } = useSelector(store => store.vendor)

    const {
        vendorDocumentData
    } = useSelector(store => store.gridData)

    const [error, setError] = useState(null)

    const rows = vendorDocumentData

    const products = productVendorList.filter(el => el.vendor === selectFilter.vendor.id)

    const columns = [
        {
            field: 'action',
            type: 'actions',
            headerName: t('grid.action'),
            width: 80,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <Box>
                        <Icon component={DeleteIcon} onClick={() => {
                            dispatch(removeVendorDocumentData(id))
                        }} />
                    </Box>
                )
            }
        },
        {
            field: 'id',
            headerName: 'id',
            width: 80,
        },
        {
            field: 'name',
            headerName: t('grid.product_name'),
            minWidth: 150,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: t('grid.unit_type'),
            minWidth: 80,
            flex: 1
        },
        {
            field: 'quantity',
            headerName: t('grid.qnt'),
            editable: true,
            type: 'number',
            minWidth: 80,
            flex: 1
        },
        {
            field: 'avg_price',
            headerName: t('grid.unit_price'),
            type: 'number',
            editable: true,
            minWidth: 80,
            flex: 1,
            cellClassName: (param) => {
                return clsx('', {
                    only_view: param.row.typePrice
                })
            },
            renderEditCell: (params) => {
                if (params.row.typePrice) {
                    return (
                        <div style={{ pointerEvents: 'none' }}>
                            {params.value}
                        </div>
                    )
                }
                return <GridEditInputCell  {...params} />
            }
        },
        {
            field: 'price',
            headerName: t('grid.total_price'),
            editable: true,
            type: 'number',
            minWidth: 80,
            flex: 1,
            cellClassName: (param) => {
                return clsx('', {
                    only_view: !param.row.typePrice
                })
            },
            renderEditCell: (params) => {
                if (!params.row.typePrice) {
                    return (
                        <div style={{ pointerEvents: 'none' }}>
                            {params.value}
                        </div>
                    )
                }
                return <GridEditInputCell {...params} />
            }
        },
        {
            field: 'type',
            type: 'actions',
            headerName: t('grid.unit_total'),
            width: 100,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <Box>
                        <Switch
                            checked={params.row.typePrice}
                            onChange={() => dispatch(tooglePriceVendorDocument(id))}
                        />
                    </Box>
                )
            }
        }

    ]

    function addHandle(e) {
        e.preventDefault()
        const findProduct = productList.find(elem => elem.id === selectFilter.product.id)
        if (findProduct) {
            let copyProduct = { ...findProduct, quantity: 0, avg_price: 0, price: 0, typePrice: true }
            copyProduct.vendor = findProduct.vendor?.name

            if (!rows.some(el => el.id === copyProduct.id)) {
                dispatch(addVendorDocumentData(copyProduct))
                // console.log(copyProduct)
                setError(null)
            } else {
                setError('Product already exist')
            }
        } else {
            setError('Vendor requared')

        }

    }

    function onEdit(row) {
        const calcAvgPrice = (row.typePrice) ? (row.price / row.quantity) : row.avg_price
        const calcTotalPrice = (row.typePrice) ? row.price : (row.avg_price * row.quantity)
        row.avg_price = (isNaN(calcAvgPrice)) ? 0 : calcAvgPrice
        row.price = (isNaN(calcTotalPrice)) ? 0 : calcTotalPrice
        dispatch(setVendorDocumentData(row))
        return row
    }

    function onSubmit() {
        if (vendorDocumentData.every(elem => elem.quantity > 0 && elem.price > 0)) {
            dispatch(setModal({ active: true, content: 'documentVendorCreate' }))
            setError(null)
        } else {
            setError('Products with 0 quantity not allowed')
        }
    }

    function setClear() {
        dispatch(clearData('vendorDocumentData'))
        dispatch(clearFilterVendor())
    }

    useEffect(() => {
        dispatch(vendorProductsAction())
        return () => setClear()
    }, [])


    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );

    const vendorsOption = vendorList.map(el => ({ label: el.full_name, id: el.id }))
    const productsOption = products.map(el => ({ label: el.name, id: el.id }))

    const handleChangeVendor = (_, value) => {
        dispatch(setSelectFilter({ filter: 'vendor', value: value }))
        dispatch(setProductVendor(value?.id))
    }

    const handleChangeProduct = (_, value) => {
        dispatch(setSelectFilter({ filter: 'product', value: value }))

    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}>
                <Typography variant="h4" align="center">Admission</Typography>
                {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
                <Box sx={{
                    display: "flex",
                    gap: 5,
                    justifyContent: 'center'
                }} component='form' onSubmit={addHandle}>
                    <FormControl disabled={!!rows.length}>
                        <Autocomplete
                            disablePortal
                            disabled={!!rows.length}
                            defaultValue={selectFilter.vendor}
                            options={vendorsOption}
                            onChange={handleChangeVendor}
                            sx={{ width: "300px" }}
                            renderInput={(params) => {
                                return <TextField disabled={!!rows.length} {...params} label="Vendors" />
                            }}
                        />
                    </FormControl>
                    {/* <FormControl disabled={!selectFilter.vendor} sx={{ width: '250px' }}>
                        <InputLabel id="product">Product</InputLabel>
                        <Select
                            value={selectFilter.product}
                            labelId="product"
                            label="Product"
                            onChange={(e) => {
                                dispatch(setSelectFilter({ filter: 'product', value: e.target.value }))
                            }}
                            required
                        >
                            {products.map(el => (
                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> */}
                    <FormControl disabled={!selectFilter.vendor} sx={{ width: '250px' }}>
                        <Autocomplete
                            disablePortal
                            disabled={!selectFilter.vendor}
                            defaultValue={selectFilter.product}
                            options={productsOption}
                            onChange={handleChangeProduct}
                            sx={{ width: "250px" }}
                            renderInput={(params) => {
                                return <TextField disabled={!selectFilter.vendor} {...params} label="Product" />
                            }}
                        />
                    </FormControl>

                    <SubmitButton endIcon={<AddIcon />} type='submit'>{t('button.add')}</SubmitButton>

                </Box>
                <Box>

                    <DataGrid
                        getRowId={(row) => row.id} //<==== a unique field
                        apiRef={apiRef}
                        rows={rows}
                        onCellClick={handleCellClick}
                        columns={columns}
                        processRowUpdate={onEdit}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        disableRowSelectionOnClick
                        sx={{
                            minHeight: '150px'
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <EntryButton disabled={!rows.length} endIcon={<DeleteIcon />} onClick={setClear}>{t('button.clear')}</EntryButton>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Link to='/admission/list'>
                            <SubmitButton>{t('button.all_admissions')}</SubmitButton>
                        </Link>
                        <SubmitButton disabled={!rows.length} endIcon={<SendIcon />} onClick={onSubmit}>{t('button.submit')}</SubmitButton>

                    </Box>
                </Box>

            </Box>
        </Box>
    )
}


export default ProductVendorPage