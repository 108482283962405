import { createAsyncThunk } from "@reduxjs/toolkit"
import { allWarhousesUnit, warhouseProductsUnit } from "../units/warhouseUnits"

export const warhouseProductsAction = createAsyncThunk('warhouse/products', async(params, {dispatch, rejectWithValue}) => {
    try {
        const response = await warhouseProductsUnit(params)
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const allWarehouseListAction = createAsyncThunk('warhouse/all', async(_, {dispatch, rejectWithValue}) => {
    try {
        const response = await allWarhousesUnit()
        return response
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


