import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBranchActionKitchen } from "../asyncActions/branchActions"
import { setSelectedBranch } from "../store/branchSlice"
import SubmitButton from "../UI/SubmitButton"
import DocumentList from "./DocumentList"
import { Link } from "react-router-dom"
import SendIcon from '@mui/icons-material/Send';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import { useTranslation } from "react-i18next"

function KicthenManHome() {

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const {
        branchList,
        selectedBranch,
        documents
    } = useSelector(store => store.branch)

    const createDocumentCondition = documents.some(doc => doc.branch.name === selectedBranch)

    useEffect(() => {
        dispatch(getBranchActionKitchen())
        return () => {
            dispatch(setSelectedBranch(0))
        }
    }, [])

    function changeHandle(e) {
        const id = e.target.value
        dispatch(setSelectedBranch(id))
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
            padding: '25px'
        }}>
            <Box sx={{ display: 'flex', gap: 3, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h5">{t('label.select_kitchen')}</Typography>
                <FormControl sx={{ width: '250px' }}>
                    <InputLabel id="kitchen">{t('label.kitchen')}</InputLabel>
                    <Select
                        defaultValue=""
                        labelId="kitchen"
                        label={t('label.kitchen')}
                        onChange={changeHandle}
                        required
                    >
                        {branchList.map((branch) =>
                            <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                        )}
                    </Select>
                </FormControl>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>
                    <Link to={!selectedBranch || createDocumentCondition ? '#' : '/create/document'}>
                        <SubmitButton fullWidth endIcon={<SendIcon />} disabled={!selectedBranch || createDocumentCondition} variant="contained">{t('button.roam_balance')}</SubmitButton>
                    </Link>
                    <Link to={!selectedBranch ? '#' : '/create/document/unschedule'}>
                        <SubmitButton disabled={!selectedBranch} fullWidth endIcon={<SendIcon />} variant="contained">{t('button.roam_balance')}</SubmitButton>
                    </Link>
                    <Link to={!selectedBranch ? '#' : '/create/document/waste'}>
                        <SubmitButton fullWidth endIcon={<AutoDeleteIcon />} disabled={!selectedBranch} variant="contained">{t('button.waste')}</SubmitButton>
                    </Link>
                </Box>
                <Box>
                    {documents.length === 0 ?
                        <Typography>{t('page_text.no_created_document')}</Typography> :
                        <DocumentList documents={documents} />
                    }
                </Box>
            </Box>
        </Box>
    )
}


export default KicthenManHome