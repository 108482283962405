import { createSlice } from "@reduxjs/toolkit";
import { userDetailAction, userListAction } from "../asyncActions/userActions";
import { allWarehouseListAction } from "../asyncActions/warhouseActions";


const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        userList: [],
        branchList: [],
        warehouseList: [],
        dataLoad: false,
        error: null,
        userDetail: {
            user: {},
            branches: [],
            warhouses: [],
        }
    },
    reducers: {
        clearUserDetail(state){
            state.userDetail = {
                user: {},
                branches: [],
                warhouses: [],
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userListAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(userListAction.fulfilled, (state, action) => {
                state.dataLoad = false
                const {
                    users,
                } = action.payload
                state.userList = users
            })
            .addCase(userListAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload || 'error'
            })

            .addCase(userDetailAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(userDetailAction.fulfilled, (state, action) => {
                state.dataLoad = false
                state.userDetail = action.payload
            })
            .addCase(userDetailAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = JSON.parse(action.payload).detail
            })

            .addCase(allWarehouseListAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(allWarehouseListAction.fulfilled, (state, action) => {
                const {
                    warehouses,
                    branches
                } = action.payload
                state.dataLoad = false
                state.branchList = branches
                state.warehouseList = warehouses
                
            })
            .addCase(allWarehouseListAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload || 'error'
            })


    }
})

export default userSlice.reducer

export const {
    clearUserDetail
} = userSlice.actions