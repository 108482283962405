import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import SubmitButton from "../UI/SubmitButton"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { userListAction } from "../asyncActions/userActions"


function UserListPage() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(userListAction())
    }, [])

    const {
        userList
    } = useSelector(store => store.user)

    const editHandle = (id) => {
        navigate(`/user/edit/${id}`) 
    }


    const rows = userList.slice().sort((a, b) => b.branches.length - a.branches.length)

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '25px'
        }}>
            <Typography variant="h4">User manager</Typography>

            <Box>
                <Link to='/user/create'>
                <SubmitButton>Add new user</SubmitButton>
                </Link>
            </Box>

            <Box>
                <TableContainer sx={{
                        padding: '20px'
                 }} 
                 component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Role</TableCell>
                                <TableCell sx={{maxWidth: '300px'}}>Branch</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((user) => (
                                <TableRow  key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.role_display || "—"}</TableCell>
                                    <TableCell align="center">
                                        {user.branches.length ?
                                            <Box sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                gap: '4px'
                                            }}>
                                                {user.branches.map(branch => (
                                                    <Box sx={{
                                                        padding: '5px',
                                                        backgroundColor: '#bb204d',
                                                        color: 'white',
                                                        borderRadius: '6px'
                                                    }}
                                                    key={branch.id}
                                                    >
                                                        {branch.name}
                                                    </Box>
                                                ))}
                                            </Box>
                                            :
                                            '—'
                                        }

                                    </TableCell>
                                    <TableCell>
                                        <SubmitButton onClick={() => editHandle(user.id)}>Edit</SubmitButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}


export default UserListPage