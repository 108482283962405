import { AppBar, Avatar, Box, Button, Container, IconButton, Menu, MenuItem, Select, Toolbar, Typography } from '@mui/material';
import ringoImage from '../assets/ringo_logo.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EntryButton from '../UI/EntryButton';
import { logout } from '../store/authSlice';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';





function Header() {

    const {
        isAuthenticated,
        username,
        role
    } = useSelector(store => store.authorization)

    const languages = [
        { code: 'am', name: 'հայկ', flag: 'https://flagcdn.com/w320/am.png' },
        { code: 'ru', name: 'Русский', flag: 'https://flagcdn.com/w320/ru.png' },
        { code: 'en', name: 'English', flag: 'https://flagcdn.com/w320/us.png' },
        { code: 'ge', name: 'Гамарджоба!', flag: 'https://flagcdn.com/w320/ge.png' },
    ];
    const { t, i18n } = useTranslation();

    const roleMenuBar = [
        { id: 1, path: '/admission', title: t('menu.admission'), roles: [1,2]},
        { id: 2, path: '/dashboard', title: t('menu.dashboard'), roles: [2,4,5]},
        { id: 3, path: '/warehouse', title: t('menu.warehouse'), roles: [1,2,4]},
        { id: 4, path: '/manage', title: t('menu.manage'), roles: [5]},
    
    ]


    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch()


    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (languageCode) => {
        i18n.changeLanguage(languageCode);
        localStorage.setItem('lang', languageCode)
        handleCloseMenu();
    };

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: '#f89e28' }}>
            <AppBar color='#f89e28' position='static'>
                <Container maxWidth='xl' sx={{ padding: 1 }}>
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
                            {isAuthenticated &&
                                <Link to='/'>
                                    <Typography variant='h5'>
                                        {t('menu.home')}
                                    </Typography>
                                </Link>
                            }
                            {role && roleMenuBar.filter(el => el.roles.includes(role)).map(menu =>
                                <Link key={menu.id} to={menu.path}>
                                    <Typography variant='h5'>
                                        {menu.title}
                                    </Typography>
                                </Link>
                            )}
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img sx={{ flexGrow: 1 }} width={100} src={ringoImage} />
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', gap: 2, justifyContent: 'end', alignItems: 'center' }}>
                            {isAuthenticated ?
                                <>
                                    <Box>
                                        <IconButton onClick={handleOpenMenu}>
                                            <Avatar
                                                src={
                                                    languages.find((lang) => lang.code === i18n.language)?.flag ||
                                                    'https://flagcdn.com/w40/us.png'
                                                }
                                                sx={{border: '1px solid black'}}
                                                alt="flag"
                                                style={{ width: 40, height: 40 }}
                                            />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleCloseMenu}
                                        >
                                            {languages.map((lang) => (
                                                <MenuItem
                                                    key={lang.code}
                                                    onClick={() => handleLanguageChange(lang.code)}
                                                >
                                                    <Avatar
                                                        src={lang.flag}
                                                        alt={lang.name}
                                                        style={{ marginRight: '10px' }}
                                                    />
                                                    {lang.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                    <Typography variant='h5'>{username}</Typography>
                                    <EntryButton endIcon={<LogoutIcon />} onClick={() => dispatch(logout())}>{t('button.log_out')}</EntryButton>
                                </> :
                                <Link to={'/login'}>
                                    <EntryButton endIcon={<LoginIcon />} variant='contained' color='error'>{t('button.login')}</EntryButton>
                                </Link>
                            }
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    )
}



export default Header