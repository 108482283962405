import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBranchAction } from "../asyncActions/branchActions"
import { Box, Typography } from "@mui/material"
import SubmitButton from "../UI/SubmitButton"
import { Link } from "react-router-dom"
import DocumentList from "./DocumentList"
import { getDocumentListAction } from "../asyncActions/documentActions"
import { useTranslation } from "react-i18next"



function WarHouseManHome() {

    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentListAction())
    }, [])

    const {
        branchName,
        branchAddress,
        documents
    } = useSelector(store => store.branch)


    const [kitchenDocuments, branchDocuments] = [
        documents.filter(el => el.branch.isKitchen),
        documents.filter(el => !(el.branch.isKitchen)),
    ]


    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px',
                width: '100%'

            }}>
                <Typography variant="h4">{t('page_text.branch_document')}</Typography>
                {branchDocuments.length === 0 ?
                    <Typography>{t('page_text.no_branch_document')}</Typography>
                    :
                    <DocumentList documents={branchDocuments} />
                }
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px',
                width: '100%'
            }}>
                <Typography variant="h4">{t('page_text.kitchen_document')}</Typography>
                {kitchenDocuments.length === 0 ?
                    <Typography>{t('page_text.no_kitchen_document')}</Typography>
                    :
                    <DocumentList documents={kitchenDocuments} />
                }
            </Box>
        </Box>
    )
}


export default WarHouseManHome