

import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBranchAction } from "../asyncActions/branchActions"
import { Box, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import DocumentList from "./DocumentList"

import { getDocumentListAction } from "../asyncActions/documentActions"
import { useTranslation } from "react-i18next"


function ManagerHomePage(){

    const {t} = useTranslation()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDocumentListAction())
    }, [])

    const {
        documents
    } = useSelector(store => store.branch)
    

    return(
        <Box sx={{
            display: 'flex', 
            flexDirection: 'column', 
            gap: 5,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 3,
                border: '1px solid #bebebe',
                borderRadius: '16px',
                padding: '25px'
            }}>
                <Typography variant="h4">{t('page_text.active_document')}</Typography>
                {documents.length === 0 ? 
                    <Typography>{t('page_text.no_branch_document')}</Typography>
                    :
                    <DocumentList documents={documents}/>
                }
            </Box>              
        </Box>
    )
}


export default ManagerHomePage
