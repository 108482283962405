import { Box, TextField, Typography } from "@mui/material"
import EntryButton from "../../../UI/EntryButton"
import SubmitButton from "../../../UI/SubmitButton"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { setModal } from "../../../store/modalSlice"
import { deleteVendorDocumentAction, updateVendorDocumentAction } from "../../../asyncActions/vendorActions"
import { useState } from "react"



function EditAdmissionContent() {
    const { t } = useTranslation()

    const dispatch = useDispatch()

    const {
        data
    } = useSelector(store => store.modal)

    const [values, setValues] = useState({
        price: data.body.price,
        quantity: data.body.quantity,
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        // Allow only numbers, a single dot, and limit to two decimal places
        const roundedValue = value.match(/^\d*(\.\d{0,2})?$/) ? value : values[name];
        setValues({
            ...values,
            [name]: roundedValue,
        });
    };


    const text = `Change product admission`

    function submitHandle() {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {
                text: 'Admission was changed!',
            }
        }
        dispatch(setModal(modalPayload))
        const body = {
            document_id: data.body.document_id,
            price: +values.price,
            quantity: +values.quantity,
        }
        dispatch(updateVendorDocumentAction(body))
    }


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography variant="h5">{text}</Typography>
            <Typography variant="h6">{data.text}</Typography>

            <Box sx={{
                display: 'flex',
                gap: 3
            }}>
                <TextField
                    label="Quantity"
                    name="quantity"
                    value={values.quantity}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                />
                <TextField
                    label="Price"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                />
            </Box>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}



export default EditAdmissionContent
