import { Box, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { createDocuemntWasteAction, updateDeclineDocumentProductsAction } from "../../../asyncActions/documentActions";
import EntryButton from "../../../UI/EntryButton";
import { useTranslation } from "react-i18next";


function DeclineDocumentContent({type}) {

    const {t} = useTranslation()

    const {
        declineDocumentData,
        createDocumentWasteData
    } = useSelector(store => store.gridData)


    const dispatch = useDispatch()
    const submitHandle = () => {
        const modalPayload = {
            active: true,
            content: 'action',
            data: {}
        }
        if (type === 'decline'){
            modalPayload.data.text = t('modal.doc.decline')
            modalPayload.data.path = '/'
            dispatch(setModal(modalPayload))
            dispatch(updateDeclineDocumentProductsAction())
        } else if (type === 'waste'){
            modalPayload.data.text = t('modal.waste_doc.waste')
            modalPayload.data.path = '/'
            dispatch(setModal(modalPayload))
            dispatch(createDocuemntWasteAction())
        }
    }


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '300px'
        }}>
            <Typography variant="h6">Total {type.toUpperCase()} products: {type === 'waste' ? createDocumentWasteData.length : declineDocumentData.length}</Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>            
            </Box>
        </Box>
    )
}


export default DeclineDocumentContent