import { createAsyncThunk } from "@reduxjs/toolkit"
import { branchProductsUnit, documentListUnit, kitchenBranchUnit, kitchenProductsUnit, salesManBranchUnit } from "../units/supplyGetUnits"



export const getBranchAction = createAsyncThunk('get/branch', async (_, {rejectWithValue}) => {
    try {
        const branch = await salesManBranchUnit()
        const documents = await documentListUnit()
        return {...branch, documents}
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const branchProductsAction = createAsyncThunk('/branch/products', async (_,{rejectWithValue}) => {
    try {
        const response = await branchProductsUnit()
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const kitchenProductsAction = createAsyncThunk('/branch/products', async (id,{rejectWithValue}) => {
    try {
        const response = await kitchenProductsUnit(id)
        return response
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})



export const getBranchActionKitchen =  createAsyncThunk('get/branch/kitchen', async (_, {rejectWithValue}) => {
    try {
        const branches = await kitchenBranchUnit()
        const documents = await documentListUnit()
        return {branches, documents}
    } catch(e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})
