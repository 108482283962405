import { instance } from "./interceptors"


export  const salesManBranchUnit = async () => {
    const response = await instance.get('/api/branch')
    return response.data
}


export const branchProductsUnit = async() => {
    const response = await instance.get('/api/branch/products')
    return response.data
}

export const kitchenProductsUnit = async(id) => {
    const response = await instance.get('/api/kitchen/products/'+id)
    return response.data
}

export const documentListUnit = async() => {
    const response = await instance.get('/api/documents')
    return response.data
}


export const documentProductsUnit =  async (id) => {
    const response = await instance.get('/api/document/'+id)
    return response.data
}


export const kitchenBranchUnit = async () => {
    const response = await instance.get('/api/kichen/branch')
    return response.data
}

export const bufferProductsUnit = async (params) => {
    const {
        category_id,
        branch_type 
    } = params
    const response = await instance.get(`/api/buffer/products/?category_id=${category_id}&is_kitchen=${branch_type === 1}`)
    return response.data
}


export const documentView = async (date) => {
    const response = await instance.get(`/api/documents/view?detail_date=${date}`)
    return response.data
}



export const vendorProductUnit = async () => {
    const response = await instance.get(`/api/vendor/products`)
    return response.data
}


export const documentVendorProductUnit = async (params) => {
    params.date = params.date.format('YYYY-MM-DD')
    const url_param = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')
    const response = await instance.get(`/api/vendor/document?${url_param}`)
    return response.data
}


export const documentVendorProductEditUnit = async (param) => {
    const {id, body} = param
    const response = await instance.patch(`/api/vendor/document/${id}`, body)
    return response.data
}


export const documentSummareDashboardUnit = async (type) => {
    const response = await instance.get(`/api/dashboard/detail?type=${type}`)
    return response.data
}