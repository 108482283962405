import { instance } from "./interceptors"


export const updateVendorDocumentUnit = async(data) => {
    const response = await instance.post('/api/vendor/document/update', data)
    return response
}

export const deleteVendorDocumentUnit = async(document_id) => {
    const response = await instance.delete('api/vendor/document/delete/'+document_id)
    return response
}
