import { Autocomplete, Box, FormControl, Icon, TextField, Typography } from "@mui/material";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SubmitButton from "../UI/SubmitButton";
import { useEffect, useState, useCallback, useRef } from "react";
import EntryButton from "../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import {  addUnscheduleDocumentData, clearData, removeUnscheduleDocumentData, setUnscheduleDocumentData } from "../store/gridDataSlice";
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { setModal } from "../store/modalSlice";
import { branchProductsAction, kitchenProductsAction } from "../asyncActions/branchActions";



const style = {
    "& .edited": {
        backgroundColor: '#f1f1f1',
        color: '#1a3e72',
    }
}



function UnscheduledDocumentPage() {

    const apiRef = useGridApiRef()
    const dispatch = useDispatch()

    const {
        role,
    } = useSelector(store => store.authorization)
    
    const {
        branchId,
    } = useSelector(store => store.branch)

    const {
       branchProducts
    } = useSelector(store => store.branchProducts)

    const {
        unscheduleDocumentData
    } = useSelector(store => store.gridData)

    const [error, setError] = useState(null)
    const [productId, setProductId] = useState(null)

    const rows = unscheduleDocumentData
    
    const columns = [
        {
            field: 'id',
            headerName: 'id',
            width: 50,
        },
        {
            field: 'product_name',
            headerName: 'Name',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'unit_type',
            headerName: 'Unit type',
            minWidth: 80,
            flex: 1
        },
        {
            field: 'quantity',
            headerName: 'Quantity',
            editable: true,
            type: 'number',
            minWidth: 80,
            flex: 1
        },  
        {
            field: 'action',
            type: 'actions',
            headerName: 'Action',
            width: 80,
            renderCell: (params) => {
                const id = params.row.id
                return (
                    <Box>
                        <Icon component={DeleteIcon} onClick={() => {
                            setProductId(null)
                            dispatch(removeUnscheduleDocumentData(id))
                        }} />
                    </Box>
                )
            }
        },
    ]


    function addHandle(e) {
        e.preventDefault()
        const findProduct = branchProducts.find(elem => elem.product_id === productId)
        if (findProduct){
            let newRow = { 
                id: findProduct.product_id,
                product_name: findProduct.product_name,
                unit_type: findProduct.unit_type,
                quantity: findProduct.qyantity,
            }
            if (!rows.some(el => el.id === newRow.id)) {
                dispatch(addUnscheduleDocumentData(newRow))
                setError(null)
            } else {
                setError('Product alredy exist')
            }

        } else {
            setError('Product requared')
        }

    }

    function onEdit(row) {
        dispatch(setUnscheduleDocumentData(row))
        return row
    }

    function onSubmit() {
        if (unscheduleDocumentData.every(elem => elem.quantity > 0)) {
            dispatch(setModal({ active: true, content: 'documentUnscheduleCreate' }))
            setError(null)
        } else {
            setError('Products with 0 quantity not allowed')
        }
    }

    function setClear() {
        dispatch(clearData('unscheduleDocumentData'))
    }

    useEffect(() => {
        if (role === 1){
            dispatch(branchProductsAction())
        } else if (role === 4){
            dispatch(kitchenProductsAction(branchId))
        }
        return () => setClear()
    }, [])


    const handleCellClick = useCallback((params) => {
        try {
            apiRef.current.startCellEditMode({
                id: params.id,
                field: params.field,
            })
        } catch (e) {
        }
    },
        [apiRef],
    );

    const productOptions = branchProducts.map(el => ({ label: el.product_name, id: el.product_id }))

    const handleChangeProduct = (_, value) => {
        setProductId(value?.id)
    }

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
            }}>
                <Typography variant="h4" align="center">Unscheduled Delivery</Typography>
                {error && <Typography sx={{ color: 'red' }}>{error}</Typography>}
                <Box sx={{
                    display: "flex",
                    gap: 5,
                    justifyContent: 'center'
                }} component='form' onSubmit={addHandle}>
                    <FormControl disabled={!!rows.length}>
                        <Autocomplete
                            disablePortal
                            options={productOptions}
                            value={branchProducts.find(el => el.id === productId)?.label || undefined}
                            onChange={handleChangeProduct}
                            sx={{ width: "300px" }}
                            renderInput={(params) => {
                                return <TextField  
                                    disabled={!!rows.length} 
                                    {...params} 
                                    label="Products" 
                                />
                            }}
                        />
                    </FormControl>
              
                    <SubmitButton endIcon={<AddIcon />} type='submit'>Add</SubmitButton>

                </Box>
                <Box sx={{height: '50vh'}}>

                    <DataGrid
                        getRowId={(row) => row.id}
                        apiRef={apiRef}
                        rows={rows}
                        onCellClick={handleCellClick}
                        columns={columns}
                        processRowUpdate={onEdit}
                        onProcessRowUpdateError={(e) => console.log(e)}
                        disableRowSelectionOnClick
                        sx={{
                            minHeight: '150px'
                        }}
                    />
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <EntryButton disabled={!rows.length} endIcon={<DeleteIcon />} onClick={setClear}>clear</EntryButton>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <SubmitButton disabled={!rows.length} endIcon={<SendIcon />} onClick={onSubmit}>submit</SubmitButton>
                    </Box>
                </Box>

            </Box>
        </Box>
    )
}


export default UnscheduledDocumentPage