import { Box, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { userCreateAction, userDeleteAction, userEditAction } from "../../../asyncActions/userActions";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const roles = [
    '',
    'Sales man',
    'Warhouse man',
    'Supplier',
    'Kitchen',
    'Manager',
]
function UserEditContent({type}){

    const {t} = useTranslation()

    const dispatch = useDispatch()

    const {
        data
    } = useSelector(store => store.modal)

    const submitHandle = () => {
        if (type === 'create'){
            dispatch(userCreateAction(data))
            const modalPayload = {
                active: true,
                content: 'action',
                data: {
                    text: 'User has been created',
                    path: '/users'
                }
            }
            dispatch(setModal(modalPayload))
        } else if (type === 'delete'){
            dispatch(userDeleteAction(data.id))
            const modalPayload = {
                active: true,
                content: 'action',
                data: {
                    text: 'User has been deleted',
                    path: '/users'
                }
            }
            dispatch(setModal(modalPayload))
        } else if (type === 'edit'){
            dispatch(userEditAction(data))
            const modalPayload = {
                active: true,
                content: 'action',
                data: {
                    text: 'User has been edited',
                    path: '/users'
                }
            }
            dispatch(setModal(modalPayload))
        }
    }

    const headerGetter = useMemo(() => {
        let value 
        if (type === 'create'){
            value = `Create new user`
        } else if (type === 'edit'){
            value = `Edit user`
        } else if (type === 'delete'){
            value = `Remove user`
        }
        return value
    }, [])

    const textGetter = useMemo(() => {
        let value 
        if (type === 'create'){
            value = `Create new user ${data.username} with role ${data.role}?`
        } else if (type === 'edit'){
            value = `Edit user ${data.body.username}?`
        } else if (type === 'delete'){
            value = `Remove user ${data.username}?`
        }
        return value
    }, [])

    return(
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography variant="h4">{headerGetter}</Typography>
            <Typography variant="h6">{textGetter}</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <EntryButton onClick={() => dispatch(setModal({active: false}))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}


export default UserEditContent