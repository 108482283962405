import { createAsyncThunk } from "@reduxjs/toolkit"
import { userCreateUnit, userDeleteUnit, userDetailUnit, userEditUnit, userListUnit } from "../units/userUnits"


export const userListAction = createAsyncThunk('user/list', async(_,{rejectWithValue}) => {
    try {
        const response = await userListUnit()
        return response.data
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const userDetailAction = createAsyncThunk('user/detail', async(param,{rejectWithValue}) => {
    try {
        const response = await userDetailUnit(param)
        return response.data
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

export const userCreateAction = createAsyncThunk('user/create', async(param,{rejectWithValue}) => {
    try {
        const response = await userCreateUnit(param)
        return response.data
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const userEditAction = createAsyncThunk('user/edit', async(param,{rejectWithValue}) => {
    try {
        const response = await userEditUnit(param)
        return response.data
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})


export const userDeleteAction = createAsyncThunk('user/delete', async(param,{rejectWithValue}) => {
    try {
        const response = await userDeleteUnit(param)
        return response.data
    } catch (e){
        return rejectWithValue(e.request?.response || 'Some error')
    }
})

