import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './authSlice'
import {
    persistStore,
    persistReducer
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import branchSlice from './branchSlice';
import branchProductsSlice from './branchProductsSlice';
// import modalSlice from './modalSlice';
import modalSlice from './modalSlice';
import postLoadSlice from './postLoadSlice';
import gridDataSlice from './gridDataSlice';
import documentProductsSLice from './documentProductsSlice';
import productWasteSlice from './productWasteSlice';
import bufferSlice from './bufferSlice'
import userSlice from './userSlice'
import warhouseSlice from './warhouseSlice'
import vendorSlice from './vendorSlice';

const authPersistConfig = {
    key: 'auth',
    storage,
    whitelist: ['username', 'token', 'role', 'isAuthenticated'],
    blacklist: ['_persist'],
}

const gridhPersistConfig = {
    key: 'data',
    storage,
    blacklist: ['_persist'],
}

const branchPersistConfig = {
    key: 'branch',
    storage,
    blacklist: ['_persist', 'selectedBranch','kicthenId', 'dateFilter'],
}

const bufferPersistConfig = {
    key: 'buffer',
    storage,
    blacklist: ['bufferList','dataLoad','dataUpdate']
}

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['_persist', 'authorization','documentProducts','branchProducts','gridData','branch','productWaste', 'buffer','vendor']
}

const vendorPersistConfig = {
    key: 'vendor',
    storage,
    blacklist: ['_persist','productList','vendorList', 'dateFilter', 'branchFilter', 'productDocumentVendotList']
}


const rootReducer = combineReducers({
    authorization: persistReducer(authPersistConfig, authSlice),
    branch: persistReducer(branchPersistConfig, branchSlice),
    documentProducts: documentProductsSLice,
    branchProducts: branchProductsSlice,
    gridData: persistReducer(gridhPersistConfig, gridDataSlice),
    productWaste: productWasteSlice,
    buffer: persistReducer(bufferPersistConfig, bufferSlice),
    modal: modalSlice,
    postLoad: postLoadSlice,
    vendor: persistReducer(vendorPersistConfig, vendorSlice),
    user: userSlice,
    warhouse: warhouseSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
      })
})

export const persistor = persistStore(store)
