import { Box, Typography } from "@mui/material"
import EntryButton from "../../../UI/EntryButton"
import SubmitButton from "../../../UI/SubmitButton"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { setModal } from "../../../store/modalSlice"
import { deleteVendorDocumentAction } from "../../../asyncActions/vendorActions"



function DeleteAdmissionContent() {
    const {t} = useTranslation()

    const dispatch = useDispatch()

    const {
        data
    } = useSelector(store => store.modal)

    const text = `Did u want to delete ${data.text}?`

    function submitHandle(){
        const modalPayload = {
            active: true,
            content: 'action',
            data: {
                text: 'Admission was delited!',
            }
        }
        dispatch(setModal(modalPayload))
        dispatch(deleteVendorDocumentAction(data.body))
    }


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography variant="h6">{text}</Typography>

            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
            }}>
                <EntryButton onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                <SubmitButton onClick={submitHandle}>{t('button.submit')}</SubmitButton>
            </Box>
        </Box>
    )
}



export default DeleteAdmissionContent
