import { Box, TextField, Typography } from "@mui/material";
import SubmitButton from "../../../UI/SubmitButton";
import EntryButton from "../../../UI/EntryButton";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../store/modalSlice";
import { useForm } from "react-hook-form";
import { documentVendorProductsEditAction } from "../../../asyncActions/vendorActions";
import { useTranslation } from "react-i18next";



function DeclineSupplyContent() {
    const {t} = useTranslation()

    const dispatch = useDispatch()


    const {
        data
    } = useSelector(store => store.modal)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const submitHandle = (fromData) => {
        const param = {
            id: data,
            body: {
                is_declined: true,
                comment: fromData.comment
            }
        }
        dispatch(documentVendorProductsEditAction(param))
        dispatch(setModal({ active: false}))
    }


    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '25px',
            width: '450px'
        }}>
            <Typography variant="h4">{t('modal.doc.comment')}</Typography>
            <form onSubmit={handleSubmit(submitHandle)} style={{ display: 'flex', flexDirection: 'column', gap: '25px', width: '400px' }}>
                <TextField
                    {...register('comment', { required: 'Requared', validate: (value) => value !== '' && value.length > 4 || 'Min length 4' })}
                    fullWidth
                    error={Boolean(errors.comment)}
                    helperText={errors.comment?.message}
                    multiline
                />

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '30px',
                    width: '100%'
                }}>
                    <EntryButton type='button' onClick={() => dispatch(setModal({ active: false }))}>{t('button.cancel')}</EntryButton>
                    <SubmitButton type='submit' >{t('button.submit')}</SubmitButton>
                </Box>
            </form>
        </Box>
    )
}


export default DeclineSupplyContent
