import { Box, Modal } from "@mui/material";
import UserEditContent from "./content/userEditContent";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../store/modalSlice";
import DeclineSupplyContent from "./content/declineSupplyContent";
import VendorDocumentContent from "./content/vendorDocumentContent";
import DocumentEditContent from "./content/documentEditContent";
import DeclineDocumentContent from "./content/declineDocumentContent";
import ActionContent from "./content/actionContent";
import DeleteAdmissionContent from "./content/deleteAdmissionContent";
import EditAdmissionContent from "./content/editAdmissionContent";

function ModalPrompt() {

    const {
        content,
        active,
    } = useSelector(store => store.modal)

    const dispatch = useDispatch()


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center'
    };

    const Content = () => {
        switch (content) {
            case 'action':
                return <ActionContent/>
            case 'userCreate':
                return <UserEditContent type='create'/>
            case 'userDelete':
                return <UserEditContent type='delete'/>
            case 'userEdit':
                return <UserEditContent type='edit'/>
            case 'declineSupply':
                return <DeclineSupplyContent/>
            case 'documentVendorCreate':
                return <VendorDocumentContent type='vendor'/>
            case 'documentUnscheduleCreate':
                return <VendorDocumentContent type='unschedule'/>
            case 'declineDocument':
                return <DeclineDocumentContent type='decline'/>                
            case 'wasteDocument':
                return <DeclineDocumentContent type='waste'/>                
            case 'createDocument':
                return <DocumentEditContent type='create'/>
            case 'submitDocument':
                return <DocumentEditContent type='submit'/>
            case 'saveDocument':
                return <DocumentEditContent type='save'/>
            case 'aproveDocument':
                return <DocumentEditContent type='aprove'/>
            // case 'declineDocument':
            //     return <DocumentEditContent type='decline'/>
            // case 'wasteDocument':
            //     return <DocumentEditContent type='waste'/>
            case 'deleteAdmission':
                return <DeleteAdmissionContent/>
            case 'editAdmission':
                return <EditAdmissionContent/>
            default:
                return <div>empty</div>
        }
    }
    return (
        <Modal
            open={active}
            onClose={() => dispatch(setModal({active: false}))}
        >
            <Box sx={style}>
                <Content />
            </Box>
        </Modal>
    )
}


export default ModalPrompt