import { createSlice } from "@reduxjs/toolkit";
import { doucmentDashboardAction, getDocumentProductsAction } from "../asyncActions/documentActions";


const documentProductsSLice = createSlice({
    name: 'documentProductsSLice',
    initialState: {
        document: {},
        documentProducts: [],
        dashboardProducts: [],
        dataLoad: false,
        error: null
    },
    reducers: {
        setError(state, action){
            state.error = action.payload
        },
        clearData(state){
            state.documentProducts = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getDocumentProductsAction.fulfilled, (state, action) => {
                state.document = action.payload.document
                state.documentProducts = action.payload.product_documents.sort((a,b) => a.product_branch.category_id - b.product_branch.category_id)
                state.dataLoad = false
                state.error = null
            })
            .addCase(getDocumentProductsAction.pending, (state) => {
                state.dataLoad = true
                state.documentProducts = []
                state.error = null
            })
            .addCase(getDocumentProductsAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problem'
            })

            .addCase(doucmentDashboardAction.fulfilled, (state, action) => {
                state.dashboardProducts = [...action.payload].sort((a,b) => a.category_name.localeCompare(b.category_name))
                state.dataLoad = false
                state.error = null
            })
            .addCase(doucmentDashboardAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(doucmentDashboardAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload?.detail && 'some problem'
            })
    }
})

export default documentProductsSLice.reducer

export const getDocumentProductData = (state) => state.documentProducts

export const {
    setError,
    clearData
} =    documentProductsSLice.actions


// branchProductsAction