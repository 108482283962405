import { createSlice } from "@reduxjs/toolkit";
import { allWarehouseListAction, warhouseProductsAction } from "../asyncActions/warhouseActions";


const warhouseSlice = createSlice({
    name: 'warhouseSlice',
    initialState: {
        products: [],
        dataLoad: false,
        error: null,
        branchList: [],
        warehouseList: [],
    },
    reducers: {
     
    },
    extraReducers: (builder) => {
        builder
            .addCase(warhouseProductsAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(warhouseProductsAction.fulfilled, (state, action) => {
                state.dataLoad = false
                state.products = action.payload.map(el => ({
                    ...el.vendor_product,
                    id: el.id,
                    quantity: el.quantity,
                    product_id: el.vendor_product.id,
                    avg_price: el.avg_price,
                }))
            })
            .addCase(warhouseProductsAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload || 'error'
            })
            .addCase(allWarehouseListAction.pending, (state) => {
                state.dataLoad = true
                state.error = null
            })
            .addCase(allWarehouseListAction.fulfilled, (state, action) => {
                const {
                    warehouses,
                    branches
                } = action.payload
                state.dataLoad = false
                state.branchList = branches
                state.warehouseList = warehouses
                
            })
            .addCase(allWarehouseListAction.rejected, (state, action) => {
                state.dataLoad = false
                state.error = action.payload || 'error'
            })
    }
})

export default warhouseSlice.reducer

export const {

} = warhouseSlice.actions