
import { createSlice } from "@reduxjs/toolkit";
import { getBufferProductsAction, updateProductBranchAction } from "../asyncActions/productsActions";

function transformProductBranches(data = []) {
    let result = [];
    data.forEach(product => {
        let transformed = { 
            id: product.product_id, 
            product: product.product_name ,
            unit_type: product.unit_type ,
        };

        product.branches.forEach(branch => {
            transformed[`branch_buffer_${branch.branch_id}`] = +branch.max_stock;
            transformed[`branch_active_${branch.branch_id}`] = branch.isActive;
        });

        result.push(transformed);
    });

    return result;
}

export const bufferSlice = createSlice({
    name: 'bufferSlice',
    initialState: {
        selectedCategory: 1,
        selectedTypeBranch: 1,
        bufferList: [],
        branchList: [],
        categoryList: [],
        kitchenList: [],
        dataLoad: false,
        dataUpdate: false
    },
    reducers: {
        setCategory(state, action){
            state.selectedCategory = action.payload
        },
        setTypeBranch(state, action){
            state.selectedTypeBranch = action.payload
        }
    }, 
    extraReducers: (builder) => {
        builder 
            .addCase(getBufferProductsAction.fulfilled, (state, action) => {
                state.bufferList = transformProductBranches(action.payload.products)
                state.branchList = action.payload.branches
                state.categoryList = action.payload.categories
                state.kitchenList = action.payload.kitchen_list
                state.dataLoad = false
            })
            .addCase(getBufferProductsAction.rejected, (state, action) => {
                state.dataLoad = false

            })
            .addCase(getBufferProductsAction.pending, (state, action) => {
                state.dataLoad = true
            })
            .addCase(updateProductBranchAction.pending, (state, action) => {
                state.dataUpdate = true
            })
            .addCase(updateProductBranchAction.rejected, (state, action) => {
                state.dataUpdate = false
            })
            .addCase(updateProductBranchAction.fulfilled, (state, action) => {
                state.dataUpdate = false
            })
    }
})



export default bufferSlice.reducer

export const getBufferData = (state) => state.buffer


export const {
    setCategory,
    setTypeBranch
} = bufferSlice.actions
