import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en_translation.json';
import geTranslation from './locales/ge_translation.json';
import amTranslation from './locales/am_translation.json';
import ruTranslation from './locales/ru_translation.json';

// Языковые ресурсы
const resources = {
  am: {
    translation: amTranslation, // Укажите ключ translation
  },
  ru: {
    translation: ruTranslation, // Укажите ключ translation
  },
  en: {
    translation: enTranslation, // Укажите ключ translation
  },
  ge: {
    translation: geTranslation, // Укажите ключ translation
  },
};


i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('lang') ?? 'en' ,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React уже экранирует текст
  },
});

export default i18n;
