import { Box, Typography } from "@mui/material";
import DocumentItem from "./DocumentItem";
import { useTranslation } from "react-i18next";


function DocumentList({documents}){

    const {t} = useTranslation()

    return (
        <Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: 5,
            }}>
                {documents.map(document => 
                        <DocumentItem key={document.id} document={document}/>
                )}

            {documents.length === 0 && <Typography>{t('page_text.empty_list')}</Typography>}
            </Box>
        </Box>
    )
}


export default DocumentList