import { instance } from "./interceptors"


export const userListUnit = async() => {
    const response = await instance.get('/api/user/list')
    return response
}

export const userCreateUnit = async(body) => {
    const response = await instance.post(`/api/user/create`,body )
    return response
}

export const userDetailUnit = async(id) => {
    const response = await instance.get(`/api/user/${id}/detail` )
    return response
}

export const userEditUnit = async(param) => {
    const {
        id, 
        body
    } = param
    const response = await instance.put(`/api/user/${id}/update`,body )
    return response
}

export const userDeleteUnit = async(id) => {
    const response = await instance.delete(`/api/user/${id}/delete` )
    return response
}
