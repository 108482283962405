import { Container } from "@mui/material"
import Header from "./components/Header"
import { Outlet } from "react-router-dom"
import WhiteBoxColumn from "./components/StyledContainer"
import ModalPrompt from "./components/modal"





function Layout(){
    return (
        <div>
            <Header/>
            <Container maxWidth='xl'>
                <WhiteBoxColumn>
                    <Outlet/>
                </WhiteBoxColumn>
            </Container>
            <ModalPrompt/>
        </div>
    )
}


export default Layout